.step_descriptions{
  width: 200px;
  margin-right: 5px;
  .step_index{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .step_label{
      display: inline-block;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border: 1px solid #999;
      border-radius: 50%;
    }
    .step_line{
      display: inline-block;
      border-top: 1px solid #999999;
      width: calc(100% - 20px);
    }
  }
}