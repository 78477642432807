.App {
  width: 100vw;
  height: 100vh;
}

.query_header {
  background-color: #fff;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 1px;
  width: 100%;
  margin-bottom: 14px;
}

.table_title {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.table_content {
  overflow-y: auto !important;
  .ant-form-item-control-input {
    width: 240px;
  }

  .table_content_list {
    background-color: #fff;
    padding: 24px;
  }
}

.common_title {
  line-height: 56px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 24px;
}

.form_item_content {
  width: 364px;
}

.form_control {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-top: 1px solid #e9e9e9;
}

.ant-form-item-label {
  width: 110px;
}
