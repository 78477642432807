.tax_container{
  // padding: 20px;
  background-color: #e7e7e7;
  height: 100%;
  width: calc(100% - 250px);
  .content{
    background-color: #fff;
    margin: 20px;
    height: calc(100vh - 100px);
    width: calc(100vw - 280px);
    padding: 20px;
    .flex_end{
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
    }
    .table_container{
      height: calc(100vh - 180px);
    }
    .success{
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: rgb(156, 231, 112);
        border-radius: 50%;
        margin-right: 5px;
    }
    .error{
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        margin-right: 5px;
    }
  }
}