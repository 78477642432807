.form_item_container{
  width: 33% !important;
  margin-bottom: 10px;

}
.options_button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
//   margin-bottom: 20px;
  margin-right: 60px;
}
.ant-form-item-control-input-content {
  .ant-picker-range {
    width: 100%;
  }
}
