.business_info_container{
    padding: 0 24px 30px;
    overflow-y: auto;
    height: calc(100vh - 50px);
    background-color:#f0f2f5 ;
    overflow-x: hidden;
    width: calc(100vw - 250px);
    .step_conrainer{
        margin-bottom: 16px;
        background-color: #fff;
    }
    // 操作日志
    .licens_info_details_log{
        background-color: #fff;
        border-radius: 2px;
        .licens_info_details_log_title{
            padding: 16px 0 16px 24px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            box-shadow: inset 0px -1px 0px #E9E9E9;
        }
        .licens_info_details_log_table{
            padding:24px ;
        }
    }
}