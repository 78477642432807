.edit_base_info {
    .ant-modal-body {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .info_item {
            width: 49%;
            margin-right: 2%;
            display: flex;
            margin: 10px 0;
            align-items: center;

            .info_item_tip {
                width: 120px;
                text-align: right;
            }

            .input {
                flex: 1;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}