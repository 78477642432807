.logout_apply {
    .apply_content {
        background-color: #fff;
        padding-bottom: 30px;

        .title {
            height: 56px;
            border-bottom: 1px solid #E9E9E9;
            font-size: 16px;
            font-weight: bold;
            line-height: 56px;
            padding-left: 20px;

            .tip {
                font-size: 14px;
                font-weight: normal;
                color: #8C8C8C;
                margin-left: 10px;
            }
        }

        .search {
            height: 40px;
            line-height: 40px;
            padding-left: 20px;
        }

        .table {
            padding: 10px 20px 0px 20px;
        }

        .upload_file {
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-top: 20px;

            .file_box {
                position: relative;

                .upload {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    z-index: 100;
                    cursor: pointer;
                }
            }
        }

        .upload_tip {
            margin-top: 12px;
            padding-left: 20px;

            .tip1 {
                color: #FFBF6B;
                margin-bottom: 4px;
            }

            .tip2 {
                color: #CCCED3;
            }
        }

        .xieyi {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 20px;
            padding-right: 20px;

            .checkbox {
                margin-right: 8px;
            }

            span {
                color: #1677FF;
            }
        }

        .btn_box {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
            padding-right: 20px;
        }
    }
}