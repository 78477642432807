.order-payment-pane-amount-cards{
  display: flex;
  .amount-card-{
    flex: 1
  }
  .ant-divider.ant-divider-vertical{
    height: auto;
    margin-left: 26px;
    margin-right: 26px;
  }
}