.add-entity-container{
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 20px;
  background-color: #f0f2f5;
}
.add-entity-content{
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  min-height: 200px;
}

.entity-form-preview-container{
  position: relative;
  display: flex;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px #d9d9d9 dashed;
  padding: 10px;
  width: 290px;
  height: 200px;
  transition: 0.25s;
  &:hover{
    border-color: #1677ff;
    .entity-form-preview-delete-icon{
      color:#1677ff;
    }
  }
  .entity-form-preview-delete-icon{
    font-size: 17px;
    cursor: pointer;
    color:#d9d9d9;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,-50%);
  }
}
.entity-form-subtitle{
  font-size: 24px;
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  position: relative;
  margin-bottom: 24px;
  &::after{
    content: '';
    height: 0;
    width: 100%;
    border-bottom: 1px solid #eee;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.entity-form-pdf-icon-container{
  .entity-form-pdf-icon{
    font-size: 36px;
  }
  .entity-form-pdf-icon-wrapper{
    display: inline-block;
    position: relative;
    .entity-form-pdf-close-icon{
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
      color: #1677ff;
      cursor: pointer;
    }
  }
  span{
    transform: translateX(-3px);
  }
}