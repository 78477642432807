.editableImageContainer:hover {
  :global {
  }
}

.editableImageContainer {
  :global {
    img{
      min-width: 104px;
      min-height: 104px;
    }
    // img.ant-image-img{
    //   object-fit: cover;
    //   transition: .5s ease-in-out;
    // }
  }
}

.introduce{
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.imageName{
}