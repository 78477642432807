.status_container {
  display: flex;
  flex-direction: row;
}

.update_control {
  display: flex;
  flex-direction: row;
  color: #1890ff;
  align-items: center;
  span {
    padding-right: 5px;
    cursor: pointer;
  }
}

.credit_fail_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  span {
    color: #f53f3f;
  }
  img {
    height: 13px;
    width: 13px;
    margin-left: 4px;
  }
}
