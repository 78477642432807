.evidence-management-content-add {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 23px;
  background-color: #f0f2f5;
  .evidence-management-countent-add-form{
      background-color: #fff;
      width: 100%;
      border-radius: 5px;
      padding: 20px 25px;
  }
  .info {
      margin-left: 100px;
      margin-top: -10px;
      width: auto;
      display:block;
      svg {
          color: #5d79c7;
      }
  }

  &-form {
      .ant-row {
          margin: 15px 0;
      }



      &-title {
          margin: 0 0 15px 0;
      }

      p {
          padding-right: 15px;
          text-align: right;
          height: 32px;
          line-height: 32px;
      }

      span {
          svg {
              color: #3a8cb1;
          }
      }
  }
}
.tab_container{
    width: 100%;
    height: 50px;
    background-color: #d2d3d4;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab_item{
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #666;
        width: 200px;
        height: 45px;
        line-height: 45px;
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
    }
    .current_tab{
        background-color: #fff;
    }
}