// .individual-business- {
//   &form-page {

.new_add {
  .image_content {
    position: relative;
    .change_img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .identity {
    .identity_content {
      padding: 24px;
      padding-bottom: 0;
      .identity_inner {
        display: flex;
        flex-direction: row;
        .ant-form-item-control-input {
          width: 134px;
        }
        .ant-form-item {
          margin-bottom: 0;
        }
        img {
          width: 104px;
          height: 104px;
        }
        .front {
          margin-right: 16px;
          .ant-form-item-label {
            width: 80px;
          }
        }
      }

      // 证件照
      .avatar_photo {
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        .avatar_photo_title {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          span {
            color: #f5222d;
          }
        }
        .avatar_photo_content {
          height: 104px;
          width: 104px;
          background: #fafafa;
          padding: 16px;
          border-radius: 4px;

          box-sizing: content-box;
          img {
            height: 104px;
            width: 104px;
          }
        }
      }
      .id_desc {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.45);
        padding-left: 64px;
      }
    }

    .id_info_box {
      // padding: 0 24px;
    }
  }
}
