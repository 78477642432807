.to_do_container{
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 16px;
    .to_do_title{
        box-shadow: inset 0px -1px 0px #E9E9E9;
        padding: 16px 0 16px 24px;
    }
    .to_do_list{
        display: flex;
        .list_container{
            padding: 24px;
            flex: 1;
            &:first-child{
                border-right: 1px solid #E9E9E9;
            }
            .list_item_container{
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                .icon{
                  width: 28px;
                  height: 28px;  
                }
                .text{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    margin: 0 10px;
                }
                .desc{
                   background: #FEF7E7;
                   border: 1px solid #FDEECE;
                    border-radius: 2px; 
                    width: 72px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    .desc_icon{
                        margin-right: 8px;
                        display: inline-block;
                        background: #FAAB0C;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                    }
                    .success_icon{
                        background: #00D18B;
                    }
                }
                .success_bg{
                    background: #E5FAF3;
                    border: 1px solid #D0F5E9;
                }
            }
            .btn_list{
                display: flex;
                .item_btn{
                    margin-right: 16px;
                }
            }
        }
    }

}