.amount-card-{
  
  &header{
    display: flex;
  }
  &title{
    color: #00000073;
    font-size: 14px;
    line-height: 22px;
  }
  &content{
    display: flex;
    align-items: center;
    gap: 24px;
  }
  &total-amount{
    flex: 1;
    font-size: 24px;
    line-height: 32px;
    color:#000000D9;
  }
  &progress-bar{
    flex: 1;
  }
  &unit{
    font-size: 16px;
    line-height: 28px;
    color: #000000A6;
  }
  &footer{
    display: flex;
    justify-content: space-between;
  }
  &unpaid-amount{
    font-size: 14px;
    line-height: 22px;
    color:#000000D9;
  }
  &amount-label{
    font-size: 14px;
    line-height: 22px;
    color: #00000073;
  }
}