.procure_conttainer{
    padding:  0 24px 20px;
    width: calc(100vw - 250px);
    height: calc(100vh - 50px);
    overflow-y: auto;
    background-color: rgba(240, 242, 245, 1);
    .search_container{
        border-radius: 2px;
        padding: 16px;
        margin-bottom: 16px;
        background-color: #fff;
    }
    .procure_list{
        padding: 20px 24px;
        border-radius: 2px;
        background-color: #fff;
        .procure_list_title{
            margin-bottom: 20px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            font-weight: 500;
            font-size: 16px;
            
        }
    }
}
.circle{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
    background-color:rgba(194, 198, 204, 1) ;
}
.pedding{
    background-color: rgba(250, 171, 12, 1);
}
.success{
    background-color: rgba(0, 209, 139, 1);
}