// .evidence_management {
//     padding: 0 20px;
//     background-color: #f0f2f5;
//     width: 100%;
//     height: 100%;

//     &-content {
//         background-color: #fff;
//         width: 100%;
//         height: 100%;
//         padding: 20px 25px;
//         p,
//         .bread_crumb_bar {
//             margin: 0;
//         }
//     }

// }



.evidence-management {
    padding: 0 20px;
    background-color: #f0f2f5;
    width: calc(100vw - 250px);
    height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    &-content {
        background-color: #fff;
        width: 100%;
        height: calc(100vh - 70px);
        padding: 0px 25px;
        p,
        .bread_crumb_bar {
            margin: 0;
        }
    }

}