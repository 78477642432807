.bank_info {
  .bank_info_content {
    padding: 24px 0;

    .bank_num {
      margin-bottom: 8px;
    }

    .bank_desc {
      margin-left: 70px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      padding-left: 40px;
      margin-left: 70px;
      margin-bottom: 24px;
    }
  }

  .ant-form-item-control-input {
    width: 260px;
  }
}