.upload_business_license_container{
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 2px;
    .upload_business_license_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 0 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        box-shadow: inset 0px -1px 0px #E9E9E9;
    }
    .upload_business_license_content{
        padding: 24px;
        .upload_business_license_btn_center{
            display: flex;
            justify-content: center;
        }
        .tooltip_warning{
            color: #FAAB0C;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            display: inline-block;
            margin-top: 5px;
            margin-left: 2px;
        }
        .mar_left{
            margin-left: 35%;
        }
    }
}