.detail-entity-container {
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 20px;
  background-color: #f0f2f5;
}

.detail-entity-content {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  min-height: 200px;
  overflow-x: hidden;

  .ant-descriptions-item-label {
    white-space: nowrap;
    color: #000F;
  }

  .ant-descriptions-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;

    .ant-descriptions-title {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .ant-descriptions-view {
    margin-left: 24px;

    .required {
      .ant-descriptions-item-label::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}