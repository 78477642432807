.add_customer {
    .block_item {
        h3 {
            font-weight: bold;
        }

        .ant-form-item-label {
            width: 145px !important;
        }
    }
}