.addUserGroup {
    .content {
        .input_item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 10px 0;

            .tip {
                width: 130px;
                text-align: right;
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                border-left: 4px solid #1677FF;
                padding-left: 10px;
            }
        }

        .foot_btn_box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .btn {
                margin: 0 10px;
            }
        }
    }
}