.enterprise_access_add {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;
}

.access_from {
  margin: 24px 0 0 0;
  .ant-form-item-label {
    width: 150px;
  }
}