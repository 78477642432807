.steps{
  .step_container_title{
    // border-bottom: 1px solid ;
    box-shadow: inset 0px -1px 0px #E9E9E9;
    padding: 16px ;
  }
  .step_container_content{
    padding: 16px;
    .steps_component{
        
    }
  }
  .step_status_container{
    padding: 16px ;
    display: flex;
    justify-content: space-between;
    // 渲染状态
    .step_status_container_label{
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.85);
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        .default_span_bg{
            display: flex;
            align-items: center;
            // width: 86px;
            padding: 0 8px;
            height: 26px;
            justify-content: center;
            margin-left: 10px;
            font-size: 14px;
            border-radius: 2px;
            background: #E5FAF3;
        }
        .default_span{
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #00D18B;
        }
        .error_bg{
            background: #FEE9E7;
        }
        .pedding{
            background: #FEF7E7; 
        }
        .success_bg{
            background: #E5FAF3;
            border: 1px solid #D0F5E9;
        }
        .pedding_bg{
            background: #ebebeb;
        }
        .error{
            margin: 0 5px;
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
           background: #F53F3F;
            border: 1px solid #FFDFDB;
        }
        .success{
            margin: 0 5px;

            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #00D18B;
        }
        .warning_bg{
            background: #FEF7E7;
            border: 1px solid #FDEECE;
        }
        .warning{
            margin: 0 5px;
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #FAAB0C;
        }
    }
    .step_operation_button_container{
        display: flex;
        align-items: center;
        .step_time{
            width: max-content;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            .countdown{
                color: #F53F3F;
                margin-left: 10px;
            }
        }
    }
  }

  .step_container{
    flex: 1;
  }
}
.upload_file{
  display: flex;
  align-items: center;
}
.upload_file_desc{
  color: #999;
  margin-left: 100px;
}
.downCount_warning{
    color: #F53F3F;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}