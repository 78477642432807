.container{
  padding: 15px 20px;
  background-color: #f0f2f5;
  width: 100%;
  height: calc(100vh - 50px);
  .content{
    padding: 20px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .create_button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
.step_container{
  .title{
    .circle{
      display: inline-block;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      font-size: 14px;
      color: rgba(2, 167, 240, 1);
      border: 1px solid rgba(2, 167, 240, 1);
      text-align: center;
      line-height: 26px;
    }
    .title{
      color: rgba(2, 167, 240, 1);
      font-size: 18px;
      margin-left: 8px;
    }
    .desc{
      color: rgba(187, 187, 187, 1);
      font-size: 14px;
      margin-left: 20px;
    }
  }
  .border_left_line{
    padding: 15px;
    border-left:  1px solid rgba(2, 167, 240, 1);
    margin-left: 13px;
  }
  .border_default{
    padding: 15px;
    border: 1px solid transparent;
    margin-left: 13px;
  }

  .field_com{
    .title{
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      margin-bottom: 15px;
    }
    .list{
      display: flex;
      align-content: flex-start;
      flex-flow: row wrap;
      justify-content: space-around;
      width: 600px;
      .default_module{
        cursor: pointer;
        margin-bottom: 20px;
        width: 125px;
        height: 40px;
        border-radius: 7px;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        border: 1px solid rgba(187, 187, 187, 1);
      }
      .select_module{
        border: 1px solid rgba(2, 167, 240, 1);
        color: rgba(2, 167, 240, 1);
      }
    }
    .list_template{
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .default_module{
        margin-left: 20px;
        cursor: pointer;
        margin-bottom: 20px;
        width: 125px;
        height: 40px;
        border-radius: 7px;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        border: 1px solid rgba(187, 187, 187, 1);
      }
      .select_module{
        border: 1px solid rgba(2, 167, 240, 1);
        color: rgba(2, 167, 240, 1);
      }
    }
  }
}

.not_allowed{
  cursor: not-allowed;
  pointer-events:none;
  background:#dddddd;
}