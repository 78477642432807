.new-order-detail-page{
  &-status-box{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // align-items: center;
    // justify-content: center;
    gap: 8px;
    .ant-tag{
      margin-right: 0;
    }
  }
  &-status-label{
    white-space: nowrap;
    font-size: 20px;
    line-height: 28px;
    color:#000000D9;
  }
  
}