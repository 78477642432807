.common_search {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
}

.common_status {
    position: relative;
    height: 22px;
    // width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mock {
        width: 100%;
        height: 100%;
        opacity: 0.2;
        // padding: 5px;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .cont {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        // font-size: 10px;
        display: flex;
        // justify-content: center;
        align-items: center;
        // padding: 8px;

        .cont_Badge {
            display: flex;
            width: 6px;
            height: 6px;
            border-radius: 50px;
            margin-right: 5px;
        }
    }
}