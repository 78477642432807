.container {
  :global {
    .ant-image-img{
      border-radius: 2px;
      // border: 1px dashed #d9d9d9;
    }
    .ant-upload-picture-card-wrapper {
      width: 100%;
      height: 100%;
      .ant-upload {
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
      }
    }
    .ant-upload.ant-upload-select-picture-card {

    }
  }
}
.uploadArea {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
