.newAdd_address {
  padding: 24px 0;
  padding-right: 24px;
  .ant-form-item-label {
    width: 160px;
  }

  .desc_img {
    img {
      width: 300px;
    }
  }
  .open_type_desc {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    margin-left: 88px;
  }
  .open_type_select {
    margin-bottom: 4px;
  }
  .sign_name_row {
    padding-top: 20px;
  }
  .sign_name {
    display: flex;
    flex-direction: row;
    .sign_name_start,
    .sign_name_end {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.85);
      background: #fafafa;
      width: 140px;
      height: 32px;
      border: 1px solid #d9d9d9;
    }
    .sign_name_end {
      width: 220px;
    }
  }
}
.new_add {
  .newAdd_address {
    .ant-form-item-control-input {
      width: auto;
      input {
        border-radius: 0;
      }
    }
  }
}
.photo_content {
  padding: 0 24px;
  .photo_desc {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    padding-left: 10px;
    span {
      color: #1890ff;
      padding-left: 10px;
      cursor: pointer;
    }
  }
  .ant-form-item-label {
    width: 138px;
  }
}
