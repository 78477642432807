.select_width{
  width: 120px;
  margin-right: 10px;
}
.span_label{
  display: inline-block;
  width: 100px;
}
.ant-checkbox-group{
  display: flex;
  flex-wrap: wrap;
}