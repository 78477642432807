.base_info {
  .base_info_content {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    padding-top: 30px;
    padding-bottom: 16px;
    .open_type {
      width: 460px;
    }
    .connect_company {
      padding-right: 24px;
      .ant-form-item {
        margin-bottom: 8px;
      }
      .desc {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        position: relative;
        left: 80px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
      }
      .ant-form-item-label {
        width: 80px;
      }
    }
  }
}
