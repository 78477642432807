.supply_order_container{
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    overflow-x: hidden;
    background-color: #f0f2f5;
    padding: 0 20px 20px;
    .supply_order_info{
        background-color: #fff;
        border-radius: 4px;
        padding-bottom: 20px;
        .title{
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 0 16px 24px;
            border-bottom: 1px solid 
rgba(233, 233, 233, 1);
        }
        .info_values{
            padding: 24px;
            .info_items{
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 8px;
                .label{
                    color: rgba(0, 0, 0, 0.51);
                }
            }
        }
    }
    .mar_top_16{
        margin-top: 16px;
    }
    .desc{
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        min-width: max-content;
    }
    .flex_b{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 16px 0 16px 24px;
        margin-bottom: 16px;
    }
    .flex_cen{
        display: flex;
        justify-content: center;
    }
}

.Businessl_icense {
    color:#000000;
    opacity: 0.45;
}