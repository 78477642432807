.supply_container{
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    overflow-x: hidden;
    
    background-color: #f0f2f5;
    padding: 0 20px 20px;
    .supply_search{
        padding: 20px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 20px;
    }
    .supply_tables{
        padding: 20px;
        background-color: #fff;
        border-radius: 4px;
        .supply_tables_button{
            margin-bottom: 16px;
            display: flex;
            justify-content: flex-end;
        }
    }
}