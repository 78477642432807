.procure_conttainer{
    padding:  0 24px 20px;
    width: calc(100vw - 250px);
    height: calc(100vh - 50px);
    overflow-y: auto;
    background-color: rgba(240, 242, 245, 1);
    overflow-x: hidden;
    .procure_list_title{
        display: flex;
        justify-content: space-between;
    }
    .search_container{
        border-radius: 2px;
        padding: 16px;
        margin-bottom: 16px;
        background-color: #fff;
    }
    .procure_list{
        padding: 20px 24px;
        border-radius: 2px;
        background-color: #fff;
        .procure_list_title{
            margin-bottom: 20px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            font-weight: 500;
            font-size: 16px;
            
        }
    }
}