.ipt{
  width: 200px;
  margin-right: 10px;
}
.ipt_col{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  margin-left: 40px;
  width: 400px;
}