.tag-custom {
  padding-top:4px;
  padding-bottom:4px;
  border-radius: 2px;
  &-default{
    background-color: #f4f7fc;
    border-color: #E9EDF6;
    .ant-badge-status-dot{
      background-color: #c2c6cc;
    }
  }
  &-processing{
    background-color: #e7f1fe;
    border-color: #D9ECFF;
    .ant-badge-status-dot{
      background-color: #007af5;
    }
  }
  &-success{
    background-color: #e5faf3;
    border-color: #D0F5E9;
    .ant-badge-status-dot{
      background-color: #00d18b;
    }
  }
  &-error{
    background-color: #fee9e7;
    border-color: #FFDFDB;
    .ant-badge-status-dot{
      background-color: #f53f3f;
    }
  }
  &-warning{
    background-color: #fef7e7;
    border-color: #FDEECE;
    .ant-badge-status-dot{
      background-color: #faab0c;
    }
  }
}