.sign-container-input-by-message {
	align-items: flex-start;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	margin: 0 auto;
	svg {
		color: #dfdfdf;
	}
	&-phone {
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		margin: {
			bottom: 20px;
		}
		button {
			margin-left: 15px;
		}
	}
	&-submit {
		width: 242px;
		margin: {
			top: 25px;
		}
	}
}
