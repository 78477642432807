.ant-descriptions {
    .ant-descriptions-row>td {
        padding-bottom: 10px;
    }
}
.anticon[tabindex] {
    color: #9AA6B8;
}
.ant-card .ant-card-body {
    padding: 24px 24px 30px 24px;
    border-radius: 0 0 8px 8px;
}