.add_tax{
  background-color: #e7e7e7;
  height: 100%;
  width: calc(100% - 250px);
  .add_tax_content{
    // background-color: #fff;
    margin: 20px;
    height: calc(100vh - 100px);
    width: calc(100vw - 280px);
    overflow: auto;
    // padding: 20px;
    .tax_top{
      padding: 20px;
      background-color: #fff;
      width: 100%;
    }
    .collapse_container{
      background-color: #fff;
      padding: 20px;
      width: 100%;
      // height: calc(100vh - 430px);
      // overflow-y: auto;
      .entrust_table{
        display: flex;
        .mar_left_10{
          margin-left: 10px;
        }
      }
      .entrust_label{
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
      }
      .entrust_button{
        margin-top: 20px;
        display: flex;
        width: 300px;
        justify-content: space-between;
      }
    }
    .quill_disabled{
      cursor: not-allowed;
      pointer-events:none;
      background:#dddddd;
    }
  }
}