.entirty_list_container{
    // padding: ;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
    padding: 0 20px 20px 20px;
    .entirty_search_container{
        padding: 20px 20px 10px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
    }
    .entirty_table_container{
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        .entirty_table_nav_flex{
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }
    .mar_r_8{
        margin-right: 8px;
    }
}