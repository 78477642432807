.container{
    .customer_content{
        background-color: #fff;
        padding: 15px;
        width: calc(100vw - 290px);
        height: calc(100vh - 80px);
        overflow-y: auto;
    }
  .flex_right{
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
}