.customer_info_tabs{
  margin-top: 20px;
  .customer_tabs_content{
    background-color: #fff;
    // height: calc(100vh - 450px);
    // overflow-y: auto;
    padding: 20px;
    min-height: 200px;
  }
}
.cus_container{
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 20px;
  background-color: #f0f2f5;
}