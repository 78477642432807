.basic_info {
    padding: 20px;
    background-color: #fff;

    .basic_content {
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                display: flex;
                align-items: center;
                font-weight: bold;

                span {
                    font-size: 14px;
                    color: #1677FF;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .btn_box {
                .btn {
                    margin: 0 10px;
                }
            }
        }

        .infos {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 20px 0;

            .info_item {
                width: 32%;
                margin-right: 2%;
                padding: 6px 0px 6px 10px;
                font-size: 14px;
                display: flex;
                justify-content: flex-start;

                .info_item_tip {
                    color: #999;
                    width: 100px;
                    text-align: right;
                }

                .info_item_content {
                    color: #333;
                    flex: 1;
                    word-break: break-all;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}