.export_inStorage_modal{
    .exportType{
        width: 100%;
        height: 30px;
        // border-bottom: 1px solid #e9e9e9;
        margin-bottom: 10px;
    }
}
.export_excel_modal{
    .export_excel_box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
        p{
            margin-bottom: 40px;
            font-weight: bold;
        }
    }
}