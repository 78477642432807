.agency_container{
  padding: 20px;
  background-color: #d4d2d2;
  height: 100%;
  width: 100vw;

  .tabs_container{
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: calc(100vh - 150px);
    .ipt{
      width: 150px;
    }
    .tabs_button{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }
}