.tableTop {
    margin-bottom: 8px;
}
.public_list_page {
    height: calc(100vh - 50px);
}
.invoiceCategoryBox {
    background-color: #fff;
    width: 100%;
    padding: 24px;
    margin-bottom: 16px;
}

.listItem {
    cursor: pointer;
    margin-right: 36px;
    padding: 4px 8px;
}

.activeListItem {
    background-color: #4096ff;
    color: #fff;
    padding: 4px 8px;
    margin-right: 36px;
    cursor: pointer;
    color: #fff !important;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #e5e6eb;
    margin: 19px 0 24px;

}
.word_all{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.default_item{
    min-width: max-content;
    // margin-bottom: 5px;
    word-break: break-all;
    border-radius: 2px;
    color: rgba($color: #000000, $alpha: 0.65);
}