.system-user {
    width: calc(100vw - 250px);
    height: 100%;
    padding: 20px;
    display: block;
    position: relative;
    background-color: #f0f2f5;
    .content{
        background-color: #fff;
        height: calc(100vh - 100px);
        padding: 20px;
    }
    &-add {
        margin-bottom: 15px;
    }
    .system-user-add{
        display: flex;
        justify-content: flex-end;
    }
}