.invoices_container{
    width: calc(100vw - 250px);
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(240, 242, 245, 1);
    padding: 6px 24px 24px;
    .invoices_search{
        margin-top: 16px;
        padding: 24px 24px 4px;
        background-color: #fff;
        border-radius: 2px;
    }
    .invoices_table_container{
        margin-top: 16px;
        padding: 0 24px 24px;
        background-color: #fff;
        border-radius: 2px;
        .invoices_table_container_title{
            padding: 16px 0;
        }
    }
    .invoces_container_list_status{
        display: flex;
        align-items: center;
        .default_icon_span{
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 8px;
        }
         .warning{
            background-color:#FAAB0C;
        }
        .success{
            background-color: #00D18B;
        }
        .primary{
            background-color: #0F76F5;
        }
        .error{
            background-color: red;
        }
    }

}