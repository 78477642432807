.top-navbar {
    width: auto;
    padding-left: 0;
    height: 50px;
    background-color: #001529;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .nav_list_content {
        margin-left: -20px;
        max-width: calc(100vw - 350px);
        overflow: hidden;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        // &::-webkit-scrollbar {
        //     /*滚动条整体样式*/
        //     width: 40px;
        //     /*高宽分别对应横竖滚动条的尺寸*/
        //     height: 3px;
        //   }
  
        //   &::-webkit-scrollbar-thumb {
        //     /*滚动条里面小方块*/
        //     border-radius: 4px;
        //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     background: #f4f7fc;
        //   }
  
        //   &::-webkit-scrollbar-track {
        //     /*滚动条里面轨道*/
        //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        //     border-radius: 4px;
        //     background: #f4f7fc;
        //   }
    }
    a {
        display: block;
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        float: left;
        color: white;
        font-size: 14px;

        &:link {
            color: #fff;
            text-decoration: none;
        }

        &:active {
            color: none;
        }

        &:visited {
            color: #fff;
            text-decoration: none;
        }

        &:hover {
            color: #1890ff;
            text-decoration: none;
        }
    }

    .navbar-link {
        display: flex;
        flex-flow: column nowrap;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        &.active,
        &:hover {
            border-bottom: 2px solid #1890ff;
        }
    }

    .logo {
        width: 270px;
        color: white;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        >p {
            margin: 0;
        }

        >img {
            width: 25px;
            height: 25px;
            margin-right: 20px;
            border-radius: 50%;
        }
    }
}

.user_info {
    position: relative;
    margin-right: 10px;
    // position: absolute;
    // z-index: 1;
    // right: 10px;
    cursor: pointer;

    .user_logo {
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    .user_name {
        margin-left: 10px;
        color: #fff;
    }

    .user_children {
        position: absolute;
        top: 30px;
        left: 0;
        display: none;
        z-index: 99;

        .user_children_item {
            background-color: #fff;
            width: 100px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            box-shadow: 0px 0px 10px rgba(#494949, 0.3);
        }
    }

    &:hover {
        .user_children {
            display: block;
        }
    }
}