.agency_sumit_info{
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 2px;
    .agency_sumit_info_title{
        color: rgba(0, 0, 0, 0.85);
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 0 16px 24px;
        box-shadow: inset 0px -1px 0px #E9E9E9;
    }
    .agency_sumit_info_content{
        padding: 24px;
        display: flex;
        align-items: flex-start;
        box-shadow: inset 0px -1px 0px #E9E9E9;
        .agency_sumit_info_content_label{
            color: rgba(0, 0, 0, 0.85);
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-right: 8px;
            display: inline-block;
            margin-top: 5px;
        }
        .agency_sumit_info_content_desc{
            color: rgba(0, 0, 0, 0.45);
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
    .agency_sumit_info_btn{
        display: flex;
        justify-content: center;
        padding: 12px 0;
    }
}
.form_item_m{
    display: flex;
    .form_item_label{
        margin-right: 8px;
    }
}