.evidence-management-content-home {
    // overflow-y: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    &-input {
        margin-bottom: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        p {
            font-size: 12px;
            margin: 0;
        }

        .ant-input {
            width: 200px;
        }
    }
    .evidence-management-content-home-table{
        // max-height: 50vh;
        // overflow-y: auto;
    }
}