.container{
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  overflow: hidden;
  .tax_content{
    // margin: 20px;
    padding: 15px;
    width: calc(100% - 30px);
    // height: calc(100% - 20px);
    height: calc(100% - 30px);
    background-color: #fff;
    overflow-y: auto;
    .status_container{
      display: flex;
      align-items: center;

      .status_icon{
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #ccc;
        border-radius: 50%;
        margin-right: 5px;
      }
      .license_success{
        background-color: rgb(156, 231, 112);
      }
      .license_error{
        background-color: red;
      }
      .license_warning{
        background-color: rgb(236, 177, 50);
      }
      .license_default{
        background-color: #ccc;
      }
    }
  }
}
.tax_admin_list_container{
    height: calc(100vh - 50px) !important;
    overflow-x: hidden;
    overflow-y: auto;
}