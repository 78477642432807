.btn_list_container{
    margin-left: 20px;
    display: flex;
    .btn_list_item{
        margin-left: 8px;
    }
}
.modal_auditRequirement{
  img{
    width: -webkit-fill-available;
  }
}