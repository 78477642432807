.login {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	height: 100vh;
	justify-content: center;
	width: 100vw;
	.sign-container {
		align-items: center;
		display: flex;
		flex-flow: column nowrap;
		height: 500px;
		justify-content: center;
		width: 500px;
		&-text {
			align-items: center;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			margin-bottom: 25px;
			width: 100%;
			img {
				height: 40px;
				margin-right: 10px;
				width: 40px;
			}
			p {
				color: #000;
				font-size: 30px;
				margin: 0;
			}
		}
		&-input {
			margin-left: 165px;
		}
		.ant-tabs {
			height: 400px;
			width: 400px;
		}
		.ant-tabs-nav-wrap {
			justify-content: center;
		}
		.ant-tabs-nav {
			margin-bottom: 25px;
			width: 250px;
			.ant-tabs-nav-list {
				div {
				}
			}
		}
		input.ant-input {
			width: 200px;
		}
	}
}
