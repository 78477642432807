.authority-modal{
    margin-bottom: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    p{
        font-size: 16px;
        margin:0;
    }
    .ant-input{
        width: 200px;
    }
}