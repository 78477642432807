.supply_order_container{
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    overflow: hidden;
    background-color: #f0f2f5;
    padding: 0 20px 20px;
    .supply_order_info{
        background-color: #fff;
        border-radius: 4px;
        .title{
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 16px 0 16px 24px;
            border-bottom: 1px solid 
rgba(233, 233, 233, 1);
        }
        .info_values{
            padding: 24px;
            .info_items{
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 8px;
                .label{
                    color: rgba(0, 0, 0, 0.51);
                }
            }
        }
    }
    .mar_top_16{
        margin-top: 16px;
    }
}

.Document_preview_transform {
    display: none;
}
// @keyframes identifier {
//     
// }
.Document_preview_mask {
    position: relative;
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);

}
.Document_close_outlined {
   position: absolute;
   right: 10px;
   top: 10px;
   color: #fff;
   font-size: 18px;
   cursor: pointer;
   z-index: 2;
}
.Document_pdf {
    position: fixed;
    width: 700px;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    overflow: auto;
    outline: 0;
    left: 30%;
    z-index: 2;
    animation: identifier-in 0.2s ease-out;
}
.Document_pdf_out {
    animation: identifier_out 0.2s ease-out;
}

@keyframes identifier-in {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes identifier_out {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}



