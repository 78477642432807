
.agency_info_details_container{
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 16px;
    padding-bottom: 24px;
    .ant-input[disabled]{
        color: #000;
    }
    .ant-radio-wrapper-disabled{
        color: #000;
    }
    .h4_title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        padding: 16px 0 16px 24px;
        
    }
    .border_btm{
        box-shadow: inset 0px -1px 0px #E9E9E9;
    }
    .basic_form_container{
        padding: 24px;
        .form_item{
            display: flex;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 8px;
            .form_item_label{
                // width: 100px;
                min-width: max-content;
                // text-align: right;
                color: rgba(0, 0, 0, 0.6);
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                margin-right: 5px;
            }
        }
    }
    .certificate_container{
        display: flex;
        padding-left: 24px;
    // justify-content: space-around;
        .flex_phone_c{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-right: 16px;
            .phone_desc{
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                display: inline-block;
                margin-top: 8px;
            }
            .flex_idNo_image{
                width: 104px;
                margin-bottom: 8px;
                height: 80px;
                img{
                    width: 104px;
                    height: 80px;
                    object-fit: cover;
                }
            }
        }
    }
}
